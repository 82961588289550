import {onError} from '@apollo/client/link/error';

import {noAuthPages} from '../appRoutes';
import {AUTH_TOKEN, cachedLocalStorage} from '../cachedLocalStorage';
import {ApolloLinkErrorContextRef} from '../context/ApolloLinkError';
import {getLog} from '../log';
import {DomainModelError, extractDomainModelError, isServerError} from './apolloUtils';

const log = getLog('apolloLinkError');

export const createApolloLinkError = (apolloLinkErrorContextRef: ApolloLinkErrorContextRef) =>
  onError(({operation, response, graphQLErrors, networkError}) => {
    graphQLErrors?.forEach((error) => {
      const domainModelError = extractDomainModelError(error);
      if (whiteListError(domainModelError)) {
        log.info(`White listed GraphQL Error`, error);
        return;
      }
      // TODO: some connection errors come as GraphQL errors, but are not DomainModelError
      if (domainModelError && (!domainModelError.status || domainModelError.status >= 500)) {
        log.error(`[GraphQL DomainModelError status >= 500]`);
        apolloLinkErrorContextRef.current?.showErrorDialog({
          title: `${domainModelError.status} ${domainModelError.statusText}`,
          content: `[${domainModelError.name}]: ${
            domainModelError.message
          } - [${domainModelError.method?.toUpperCase()}] ${domainModelError.url}`,
        });
      } else {
        log.info(`[GraphQL error]`, error);
      }
      apolloLinkErrorContextRef.current?.showErrorToast(error.message);
    });

    if (networkError) {
      if (isServerError(networkError)) {
        const {message, statusCode, error} =
          typeof networkError.result === 'object' ? networkError.result : {...networkError, error: ''};
        if (networkError.statusCode === 401) {
          //  TODO: return 200 from graphql endpoint with graphql Unauthenticated error
          log.warn(`Got 401 unauthorized response from GraphQL endpoint`, networkError);
          cachedLocalStorage.remove(AUTH_TOKEN);
          if (!noAuthPages.includes(window.location.pathname)) {
            apolloLinkErrorContextRef.current?.showUnauthenticatedDialog(message);
          }
        } else if (networkError.statusCode === 403) {
          //  TODO: return 200 from graphql endpoint with graphql Forbidden error
          log.warn(`Got 403 unauthorized response from GraphQL endpoint`, networkError);
          apolloLinkErrorContextRef.current?.showErrorDialog({
            title: `Forbidden`,
            content: message,
          });
        } else if (networkError.result) {
          log.error(`[Network error]: `, networkError.result);
          apolloLinkErrorContextRef.current?.showErrorDialog({
            title: `${statusCode} ${error}`,
            content: message,
          });
        }
      } else {
        log.error(`[Network error]: `, networkError);
        apolloLinkErrorContextRef.current?.showErrorDialog({
          title: `Network error`,
          content: networkError.message,
        });
      }
    }
  });

const whiteListError = (e?: DomainModelError) => {
  return e && e.code === 'FIELD_NOT_FOUND' && e.entity === 'Product' && e.field === 'technical';
};
