import {getLog} from '../log';

const log = getLog('useLoading', 'INFO');

interface Useloading {
  isLoading: boolean;
  reset: () => void;
}

interface QueryState {
  query: string;
  isLoading: boolean;
}

let loadingQueries: string[] = [];

const addLoadingQuery = (query: string) => {
  loadingQueries.push(query);
};

const removeLoadingQuery = (query: string) => {
  loadingQueries.splice(loadingQueries.indexOf(query), 1);
};

const reset = () => {
  loadingQueries = [];
};

/**
 * Custom hook to manage loading state based on query states.
 *
 * Hook must be called from the component that contains the PageSubtitle/PageTitle.
 * Otherwise, nested components don't rerender when the loading state changes, resulting in the loading spinner not showing.
 */
export const useLoading = (queryStates?: QueryState[]): Useloading => {
  queryStates?.forEach(({query, isLoading}) => {
    isLoading ? addLoadingQuery(query) : removeLoadingQuery(query);
  });

  return {isLoading: Object.keys(loadingQueries).length > 0, reset};
};
