import {gql, useQuery} from '@apollo/client';
import React, {useMemo} from 'react';

import rolesQuery from '../graphql/query/roles.graphql';

import {getLog} from '../log';
import {ID, Role} from '../model';

const log = getLog('useRoles', 'INFO');

interface RolesQuery {
  roles: Role[];
}

interface UseRoles {
  loading: boolean;
  roles: Role[];
  teliaRoles: Role[];
  customerRoles: Role[];
  getRole: (roleId: ID) => Role | undefined;
}

export const useRoles = () => {
  const _nameCompare = (a: Role, b: Role) => a.name.localeCompare(b.name);

  const {loading, data} = useQuery<RolesQuery>(gql(rolesQuery), {fetchPolicy: 'cache-first'});

  const useRolesHook: UseRoles = useMemo(() => {
    const roles = (data?.roles.slice() || []).sort(_nameCompare);
    return {
      loading,
      roles,
      teliaRoles: roles.filter(({userType}) => userType === 'TELIA'),
      customerRoles: roles.filter(({userType}) => userType === 'CUSTOMER'),
      getRole: (roleId: ID) => roles.find(({id}) => id === roleId),
    };
  }, [data, loading]);

  log.debug('render', useRolesHook);
  return useRolesHook;
};
