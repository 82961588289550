import {gql, useQuery} from '@apollo/client';
import {sort} from '@telia/cpa-web-common';
import {useMemo} from 'react';

import countriesQuery from '../graphql/query/countries.graphql';

import {getLog} from '../log';
import {Country, ID} from '../model';
import {useUser} from './useUser';

const log = getLog('useCountries', 'INFO');

interface CountriesQuery {
  countries: Country[];
}

export interface CountriesHook {
  loading: boolean;
  countries: Country[] | undefined;
  getCountry: (id: ID) => Country | undefined;
  getName: (id: ID) => string;
}

export const useCountries = () => {
  const {loading, data: {countries} = {}} = useQuery<CountriesQuery>(gql(countriesQuery), {fetchPolicy: 'cache-first'});

  const sortedCountries = useMemo(() => countries?.slice().sort(sort.sortByNameComparator), [countries]);
  const getCountry: (id: ID) => Country | undefined = (countryId: ID) => countries?.find(({id}) => id === countryId);
  const getName: (id: ID) => string = (id: ID) => getCountry(id)?.name || id;

  const useCountriesHook: CountriesHook = {
    loading,
    countries: sortedCountries,
    getCountry,
    getName,
  };
  log.debug(useCountriesHook);
  return useCountriesHook;
};
