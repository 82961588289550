import {ProductTechnical} from '@telia/cpa-web-common/dist/model';
import {Button} from '@telia/styleguide';
import classNames from 'classnames';
import React, {FC, useEffect, useState} from 'react';

import '../common/field/Field.scss';
import './ServiceTechnicalFeatures.scss';

import {FormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {Icon} from '../common/Icon';
import SectionTitle from '../common/SectionTitle';
import Tooltip from '../common/Tooltip';
import {Field} from '../common/field';

const log = getLog('ProductTechnicalFeatures', 'INFO');

interface ServiceTechnicalFeaturesProps {
  formState: FormState;
}

interface Feature {
  featureKey: string;
  featureValue: string;
}

const EMPTY_FEATURE_ROW = {
  featureKey: '',
  featureValue: '',
};

export const ServiceTechnicalFeatures: FC<ServiceTechnicalFeaturesProps> = ({formState}) => {
  const {isEditing} = formState;
  const technical = formState.entityAs<ProductTechnical>();

  const initialFeatureRows = (): Feature[] => {
    return (
      technical.features?.split(';').map((row) => {
        const feature = row.split('=');
        return {featureKey: feature[0], featureValue: feature[1]};
      }) || []
    );
  };
  const [featureRows, setFeatureRows] = useState<Feature[]>(initialFeatureRows());

  useEffect(() => {
    if (!isEditing) {
      const rows = initialFeatureRows();
      if (
        rows.length !== featureRows.length ||
        rows.some(
          ({featureKey, featureValue}, i) =>
            featureKey !== featureRows[i].featureKey || featureValue !== featureRows[i].featureValue
        )
      ) {
        setFeatureRows(rows);
      }
    }
  }, [technical]);

  useEffect(() => {
    const newFeatures = featureRows.map((row) => `${row.featureKey}=${row.featureValue}`).join(';');
    log.debug('newFeatures', newFeatures);
    formState.onChange('features')(newFeatures);
  }, [featureRows]);

  const deleteRow = (rowIndex: number) => {
    const updatedFeatureRows = featureRows.clone();
    updatedFeatureRows.splice(rowIndex, 1);
    setFeatureRows(updatedFeatureRows);
  };

  log.debug('features', {features: technical.features});

  return (
    <section className={'form-group'}>
      <SectionTitle>
        Service Features
        <Tooltip text="Service features will be encoded on the format 'key1=value1;key2=value2', for use in other services." />
      </SectionTitle>
      {featureRows &&
        !featureRows.isEmpty() &&
        featureRows?.map(({featureKey: featureKey, featureValue: featureValue}, i) => (
          <FormRow key={`feature-row-${i}`}>
            <FormColumn>
              <Field
                {...(i === 0 && {label: 'Key'})}
                value={featureKey}
                placeholder="Key"
                onChange={(value) => {
                  const newTechnicalRows = featureRows.clone();
                  newTechnicalRows[i].featureKey = value?.toString() || '';
                  setFeatureRows(newTechnicalRows);
                }}
                isEditing={isEditing}
              />
              <Field
                {...(i === 0 && {label: 'Value'})}
                value={featureValue}
                placeholder="Value"
                onChange={(value) => {
                  const newTechnicalRows = featureRows.clone();
                  newTechnicalRows[i].featureValue = value?.toString() || '';
                  setFeatureRows(newTechnicalRows);
                }}
                isEditing={isEditing}
              />
              {isEditing && (
                <div className="product__technical__features__icon--wrapper">
                  <Icon
                    icon="trash"
                    onClick={() => deleteRow(i)}
                    info="Delete"
                    className={classNames(
                      'product__technical__features__icon',
                      'product__technical__features__icon--delete'
                    )}
                  />
                </div>
              )}
            </FormColumn>
          </FormRow>
        ))}
      {(!featureRows || featureRows.length === 0) && (
        <FormRow>
          <FormColumn>
            {!isEditing ? (
              <>
                <Field label="Key" placeholder="Key" isEditing={isEditing} />
                <Field label="Value" placeholder="Value" isEditing={isEditing} />
              </>
            ) : (
              <>
                <label className="label">Key</label>
                <label className="label">Value</label>
              </>
            )}
          </FormColumn>
        </FormRow>
      )}
      {isEditing && (
        <FormRow>
          <FormColumn>
            <div>
              <Button
                kind="normal"
                text="Add row"
                onClick={() => setFeatureRows((currRows) => [...currRows, {...EMPTY_FEATURE_ROW}])}
              />
            </div>
          </FormColumn>
        </FormRow>
      )}
    </section>
  );
};
