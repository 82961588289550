import {gql} from '@apollo/client';
import {RequestAccessInput} from '@telia/cpa-web-common/src/model';
import {Button} from '@telia/styleguide';
import React from 'react';
import {FC} from 'react';

import requestAccessMutation from '../../graphql/mutation/requestAccess.graphql';

import * as AppRoutes from '../../appRoutes';
import {Entity, useFormState} from '../../hooks/useFormState';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {User} from '../../model';
import Form from '../common/Form';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SelfServiceUser} from '../shared/SelfServiceUser';

const log = getLog('RequestAccess', 'INFO');

export const RequestAccess: FC = () => {
  const {loadingUser, realUser, hasRealUserPermission, logout} = useUser();

  const formState = useFormState({isEditing: true, initialEntity: realUser as unknown as Entity});
  const requestAccess = useMutationWrap<{user: User}, {user: RequestAccessInput}>(gql(requestAccessMutation));

  const onRequest = () => {
    const {firstName, lastName, email, phone, description} = formState.entityAs<User>();

    requestAccess({
      loadingText: 'Requesting access...',
      successText: 'Access requested',
      variables: {
        user: {firstName, lastName, email, phone, description: description || ''},
      },
      update: (proxy, {data}) => {
        // Force reload to refetch user
        window.location.href = AppRoutes.USER;
      },
    });
  };

  return (
    <>
      <PageSubtitle subtitle="Request Access to Telia Bulk Messaging Web" />
      <PageViewCounter page="request-access" />
      <p>
        To get access to Telia Bulk Messaging Web please enter your information as well as specify a reason to get
        access and what role you will fulfill.
      </p>
      <Form>
        <SelfServiceUser formState={formState} />
        <div>
          <Button text="Request Access" kind={Button.kinds.primary} onClick={onRequest} />
          <Button text="Back to Startpage" kind={Button.kinds.cancel} onClick={logout} />
        </div>
      </Form>
    </>
  );
};
