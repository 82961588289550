import {gql, useQuery} from '@apollo/client';
import {dates, format} from '@telia/cpa-web-common';
import {colors as teliaColors} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useMemo, useState} from 'react';
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';

import settlementsTotalsQuery from '../../graphql/query/settlementsTotals.graphql';

import './ReportMonthlyUsageGraph.scss';

import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Dict, ID, PropsWithClassName, SettlementMonthTotals} from '../../model';
import Loading from '../Loading';
import {InformationLineFc} from '../common/InformationLine';
import {ReportPendingInfoFc} from './ReportPendingInfo';

const log = getLog('ReportMonthlyUsageGraph', 'INFO');

export interface ReportMonthlyUsageGraphFcProps extends PropsWithClassName {
  onClick: (month: ID) => void;
  years: number[];
  contractType: string;
  productType: string;
}

export interface SettlementPeriod {
  month: string;
  year: string;
}

interface SettlementsTotalsQuery {
  settlementsTotals: SettlementMonthTotals[];
}

const colors = [
  teliaColors.corePurple500,
  teliaColors.green500,
  teliaColors.red500,
  teliaColors.orange500,
  teliaColors.blue500,
];

interface CustomToolTipProps {
  value: string;
  name: string;
  label: string;
  payload: any[];
}

const getYearFromDataKey = (dataKey: string) => dataKey.split('.')[0];

const CustomTooltip = (props: CustomToolTipProps) => {
  log.debug('custom tooltip props', props);

  return (
    <div className="tooltip__wrapper">
      <h5 className="heading--level-4 tooltip__heading">{props.label}</h5>
      <table>
        <tbody>
          {props.payload?.map((row) => (
            <tr key={row.name} className="tooltip__row">
              <td>
                <div className="tooltip__circle" style={{backgroundColor: row.stroke}} />
              </td>
              <td>{getYearFromDataKey(row.name)}:</td>
              <td className="rightText tooltip__value">{format.currency(Number(row.value), '', false)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const ReportMonthlyUsageGraphFc: FC<ReportMonthlyUsageGraphFcProps> = ({
  years,
  contractType,
  productType,
  onClick,
  className,
}) => {
  const {currentUserBrand} = useUser();

  years = years.slice().sort().reverse();

  const {loading, data: {settlementsTotals} = {}} = useQuery<SettlementsTotalsQuery>(gql(settlementsTotalsQuery), {
    skip: !currentUserBrand,
    variables: {years},
  });
  log.debug('props', settlementsTotals);

  const onGraphClick: (nextState: {activeLabel: string}) => void = (nextState) => {
    log.debug('onClick', nextState);

    if (nextState && nextState.activeLabel) onClick(dates.monthMm(nextState.activeLabel));
  };

  const [notComplete, setNotComplete] = useState<SettlementPeriod>();
  const [notApproved, setNotApproved] = useState<SettlementPeriod>();

  const data = useMemo(() => {
    let dataMap: Dict<Dict<Dict<{internal: number; external: number; all: number}>>> = {};

    setNotComplete(undefined);
    setNotApproved(undefined);

    settlementsTotals?.forEach((monthTotal) => {
      const monthMm = monthTotal.period.substring(4, 6);
      const month = format.monthName(monthMm);
      const year = monthTotal.period.substring(0, 4);
      if (!monthTotal.isComplete) {
        setNotComplete({month: monthMm, year});
      }
      if (!monthTotal.isApproved) {
        setNotApproved({month: monthMm, year});
      }
      monthTotal.products.forEach((product) => {
        dataMap[month] = {
          ...dataMap[month],
          [monthTotal.period.substring(0, 4)]: {
            ...(dataMap[month] ? dataMap[month][monthTotal.period.substring(0, 4)] : {}),
            [product.productId]: {
              internal: product.INTERNAL,
              external: product.EXTERNAL,
              all: product.INTERNAL + product.EXTERNAL,
            },
          },
        };
      });
    });
    return Object.keys(dataMap).map((key) => ({...dataMap[key], month: key}));
  }, [settlementsTotals]);

  log.debug('data', data);

  const lineProps = {
    type: 'monotone' as any,
    strokeWidth: 2,
    dot: {strokeDasharray: '', strokeWidth: 4, r: 0.5},
    isAnimationActive: false,
  };

  return (
    <>
      {!!years.length && <ReportPendingInfoFc incompleteDate={notComplete} unapprovedDate={notApproved} />}
      <div
        className={classnames('SettlementTotalsGraph', className)}
        style={{
          width: '900px',
          height: '600px',
        }}
      >
        {loading ? (
          <Loading />
        ) : !settlementsTotals || settlementsTotals.isEmpty() || !years.length ? (
          <InformationLineFc description={`No settlement data` /* on ${month} ${year}*/} />
        ) : (
          <LineChart
            width={900}
            height={600}
            data={data}
            margin={{top: 5, right: 0, left: 0, bottom: 5}}
            onClick={onGraphClick}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="month" allowDuplicatedCategory={false} fontSize={10} padding={{left: 25, right: 25}} />
            <YAxis
              fontSize={10}
              tickCount={8}
              tickFormatter={(value) => format.currency(Number(value), '')}
              tick={{width: 100}} // To avoid line break in tick label
            />
            <Tooltip
              animationDuration={100}
              formatter={(value, name) => [format.currency(Number(value), ''), getYearFromDataKey(name.toString())]}
              wrapperStyle={{outline: 'none'}}
              contentStyle={{alignItems: 'end'}}
              content={CustomTooltip}
            />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="top"
              height={36}
              iconType="plainline"
              formatter={getYearFromDataKey}
            />
            {years.map((year, i) => (
              <React.Fragment key={year + 'Fragment'}>
                <Line
                  key={year + '.' + productType.toLowerCase() + '.' + contractType.toLowerCase()}
                  {...lineProps}
                  dataKey={year + '.' + productType.toUpperCase() + '.' + contractType.toLowerCase()}
                  strokeDasharray=""
                  stroke={colors[i]}
                />
              </React.Fragment>
            ))}
          </LineChart>
        )}
      </div>
    </>
  );
};
