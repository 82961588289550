import {gql} from '@apollo/client';
import {Button, ModalDialog} from '@telia/styleguide';
import React, {FC, useState} from 'react';

import acceptSettlementMutation from '../../graphql/mutation/acceptSettlement.graphql';
import generateSettlementsMutation from '../../graphql/mutation/generateSettlements.graphql';

import './SettlementPendingList.scss';

import {useLoading} from '../../hooks/useLoading';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useSelectedIds} from '../../hooks/useSelectedIds';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, Settlement} from '../../model';
import {SETTLEMENTS_ADJUST} from '../../permissions';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SettlementTableFc} from './SettlementTable';
import {PendingSettlementFc} from './common/PendingSettlement';
import {SettlementTotalSumFc} from './common/SettlementTotalSum';

const log = getLog('SettlementPendingList', 'INFO');

export interface SettlementPendingListProps {
  onUpdateSettlement: (settlement: Settlement) => void;
  pendingSettlements?: Settlement[];
  loading: boolean;
}

export const SettlementPendingListFc: FC<SettlementPendingListProps> = ({
  pendingSettlements,
  onUpdateSettlement,
  loading,
}) => {
  useLoading([{query: 'pendingSettlements', isLoading: loading}]);
  const {selectedIds, onSelectId, onSelectAll, isSelected, areAllSelected} = useSelectedIds<Settlement>([]);
  const [showAcceptSelectedDialog, setShowAcceptSelectedDialog] = useState<boolean>(false);
  const {hasAnyPermission} = useUser();
  const canAdjust = hasAnyPermission(SETTLEMENTS_ADJUST);
  const {showModal} = useModal();

  const onShowSettlement = (settlement: Settlement) => {
    log.debug('onShowSettlement', settlement.id);
    showModal({
      title: `${settlement.id} ${settlement.customer.company.name}`,
      content: <PendingSettlementFc settlement={settlement} onAccept={() => onAcceptSettlement(settlement.id)} />,
    });
  };

  const onApproveSelected = () => {
    setShowAcceptSelectedDialog(true);
  };

  const onCancelApproveSelected = () => {
    setShowAcceptSelectedDialog(false);
  };

  const onConfirmApproveSelected = () => {
    log.info(`onConfirmApproveSelected: ${selectedIds.length} Settlements`);
    setShowAcceptSelectedDialog(false);
    selectedIds.forEach((settlementId) => onAcceptSettlement(settlementId));
  };

  const acceptSettlement = useMutationWrap<{acceptSettlement: Settlement}, {id: ID}>(gql(acceptSettlementMutation));
  const onAcceptSettlement = (id: ID) => {
    log.debug(`onAcceptSettlement`, id);
    acceptSettlement({
      loadingText: `Accepting settlement ${id}...`,
      successText: `Settlement ${id} accepted`,
      variables: {id},
      update: (proxy, {data}) => {
        const {acceptSettlement} = data || {};
        log.info('acceptSettlementMutate update', acceptSettlement);
        // Update the pending settlements lists in cache
        acceptSettlement && onUpdateSettlement(acceptSettlement);
      },
    }).then(({data}) => {
      const {acceptSettlement} = data || {acceptSettlement: undefined};
      log.debug('acceptSettlementMutate resolved', acceptSettlement);
      acceptSettlement && onSelectId(acceptSettlement.id)(false);
      return acceptSettlement;
    });
  };

  const generateSettlements = useMutationWrap<{year: number; month: number}, {}>(gql(generateSettlementsMutation));
  const onGenerateSettlements = () => {
    log.debug('onGenerateSettlements');
    generateSettlements({
      loadingText: 'Requesting settlements generation...',
      successText: 'Settlements generation accepted',
      variables: {},
    }).then(({data}) => {
      const {year, month} = data || {year: undefined, month: undefined};
      log.debug('generateSettlementsMutate resolved', {year, month});
      return {year, month};
    });
  };

  log.debug('render');

  return (
    <Page>
      {showAcceptSelectedDialog && (
        <ModalDialog
          name={'accept-settlements'}
          heading={'Accept selected settlements'}
          submitText="Accept"
          submitKind={Button.kinds.primary}
          onSubmit={onConfirmApproveSelected}
          closeText="Cancel"
          onClose={onCancelApproveSelected}
        >
          <p>Are you sure you want to ACCEPT {selectedIds && selectedIds.length} settlements?</p>
        </ModalDialog>
      )}

      <PageSubtitle>
        Pending Settlements <SettlementTotalSumFc settlements={pendingSettlements} />
      </PageSubtitle>
      <PageViewCounter page="pending" />

      {!pendingSettlements || pendingSettlements.isEmpty() ? (
        <InformationLineFc
          description={'No pending settlements to be approved'}
          buttons={canAdjust && <Button text={'Generate Settlements'} onClick={onGenerateSettlements} />}
        />
      ) : (
        <>
          {canAdjust && (
            <div className={'pending-settlements__approve-all-form'}>
              <FormRow>
                <FormColumn>
                  <Field
                    type={FieldTypes.checkbox}
                    isEditing={true}
                    label={'Select all'}
                    value={areAllSelected(pendingSettlements)}
                    onChange={onSelectAll(pendingSettlements)}
                  />
                </FormColumn>
                <FormColumn>
                  <Button
                    kind={Button.kinds.primary}
                    text={`Accept selected (${selectedIds.length})`}
                    onClick={onApproveSelected}
                    isDisabled={selectedIds.isEmpty()}
                  />
                </FormColumn>
              </FormRow>
            </div>
          )}

          <SettlementTableFc
            settlements={pendingSettlements}
            onShowSettlement={onShowSettlement}
            isSelected={isSelected}
            onSelectId={canAdjust ? onSelectId : undefined}
          />
        </>
      )}
    </Page>
  );
};
