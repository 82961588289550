import {gql, useQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import smscsQuery from '../../graphql/query/smscs.graphql';

import * as AppRoutes from '../../appRoutes';
import {useLoading} from '../../hooks/useLoading';
import {getLog} from '../../log';
import {Brand, Smsc} from '../../model';
import FormColumn, {FormColumnSizeNormal} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import PageSubtitle from '../common/PageSubtitle';
import {PageViewCounter} from '../metrics/PageViewCounter';

const log = getLog('SmscList', 'INFO');

export interface SmscsQuery {
  smscs: Smsc[];
}

interface SmscListProps {
  currentUserBrand?: Brand;
}

export const SmscListFc: FC<SmscListProps> = ({currentUserBrand}) => {
  const {loading, data: {smscs} = {}} = useQuery<SmscsQuery>(gql(smscsQuery), {
    variables: {
      countryId: currentUserBrand?.countryId,
    },
    skip: !currentUserBrand,
  });
  useLoading([{query: 'smscs', isLoading: loading}]);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  log.debug('render', smscs);

  return (
    <>
      <PageSubtitle subtitle="SMSCs" />
      <PageViewCounter page="smscs" />

      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <Link to={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, AppRoutes._CREATE)}>
            <Button text={'Create SMSC'} />
          </Link>
        </FormColumn>
      </FormRow>

      <div>
        {smscs && !smscs.isEmpty() ? (
          <List id="smscList">
            {smscs.map((smsc) => (
              <ListItem
                key={smsc.id}
                className={classnames('noPadding', 'marginBottomHalfRem')}
                link={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, smsc.id)}
              >
                <div>
                  <span className={classnames('bold')}>{smsc.name}</span>
                  <div className={classnames('noHover', 'blackText')}>
                    <span className="greyText">{smsc.description ? 'Description: ' : 'No description'}</span>
                    {smsc.description}
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <InformationLineFc>No SMSCs found</InformationLineFc>
        )}

        {smscs && smscs.length > 10 && (
          <FormRow>
            <FormColumn size={FormColumnSizeNormal}>
              <Link to={formatWithBrand(AppRoutes.MANAGEMENT_SMSCS__smsc, AppRoutes._CREATE)}>
                <Button text={'Create Message Channel'} />
              </Link>
            </FormColumn>
          </FormRow>
        )}
      </div>
    </>
  );
};
