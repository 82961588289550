import {gql, useLazyQuery} from '@apollo/client';
import {Button} from '@telia/styleguide';
import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import settlementQuery from '../../graphql/query/settlement.graphql';

import './SettlementPendingList.scss';

import * as AppRoutes from '../../appRoutes';
import {getLog} from '../../log';
import {ID, Settlement} from '../../model';
import Loading from '../Loading';
import Error from '../common/Error';
import Form from '../common/Form';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes} from '../common/field/Field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SettlementFc} from './common/Settlement';

const log = getLog('SettlementSearch', 'INFO');

interface SettlementQuery {
  settlement: Settlement;
}

export const SettlementSearchFc: FC = (props) => {
  const {settlementId} = useParams<{settlementId: ID}>() as {settlementId: ID};
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const [searchString, setSearchString] = useState<string>(settlementId);
  const [search, {data, loading, error}] = useLazyQuery<SettlementQuery>(gql(settlementQuery));
  const {settlement} = data || {};
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    searchInputRef?.current?.focus();
  }, [searchInputRef.current]);
  useEffect(() => {
    settlementId &&
      !settlementId.isEmpty() &&
      search({
        variables: {id: searchString},
      });
  }, [settlementId]);

  const onSearch = () => {
    log.debug('onSearch', searchString);
    navigate(formatWithBrand(AppRoutes.SETTLEMENT_SEARCH_RESULT, searchString));
  };

  log.debug('render', {settlementId, searchString, loading, data, error});
  return (
    <Page>
      <PageSubtitle subtitle="Search" showLoading={false} />
      <PageViewCounter page="search" />

      <Form onSubmit={onSearch}>
        <FormRow>
          <FormColumn>
            <Field
              type={FieldTypes.input}
              isEditing={true}
              placeholder={'settlement number'}
              icon={'search'}
              onChange={(value) => {
                setSearchString((value as string) || '');
              }}
              value={searchString}
              inputRef={searchInputRef}
            />
          </FormColumn>
          <FormColumn>
            <div>
              <Button
                kind={Button.kinds.primary}
                text={'Search'}
                onClick={onSearch}
                // isDisabled={searchString.isEmpty()}
              />
            </div>
          </FormColumn>
        </FormRow>
      </Form>

      {loading ? (
        <Loading />
      ) : error ? (
        <Error text={`Couldn't find settlement number: ${searchString}`} />
      ) : settlement === undefined ? (
        <InformationLineFc description={`Introduce the settlement number and click SEARCH`} />
      ) : (
        <SettlementFc settlementId={settlement.id} customerId={settlement.customer.id} />
      )}
    </Page>
  );
};
