import React, {FC, PropsWithChildren} from 'react';

import './PageSubtitle.scss';

import {useLoading} from '../../hooks/useLoading';
import Loading from '../Loading';

interface PageSubtitleProps {
  subtitle?: string;
  showLoading?: boolean;
}

const PageSubtitle: FC<PropsWithChildren<PageSubtitleProps>> = ({subtitle, children, showLoading = true}) => {
  const {isLoading, reset} = useLoading();
  reset();

  return (
    <h2 className="page-subtitle__subtitle heading heading--level-2">
      {subtitle || children}
      <br />
      {showLoading && isLoading && (
        <div className="page-subtitle__spinner">
          <Loading />
        </div>
      )}
    </h2>
  );
};

export default PageSubtitle;
