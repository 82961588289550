import {constants, dates} from '@telia/cpa-web-common';
import classnames from 'classnames';
import React, {FC, useMemo} from 'react';

import {useFormState} from '../../hooks/useFormState';
import {useProductTypes} from '../../hooks/useProductTypes';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, PropsWithClassName} from '../../model';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {ReportMonthSummariesTableFc} from './ReportMonthSummariesTable';
import {ReportMonthlyUsageGraphFc} from './ReportMonthlyUsageGraph';

const log = getLog('ReportMontlyUsage', 'INFO');
const {ALL, contractTypes, EXTERNAL_CONTRACT_TYPE, INTERNAL_CONTRACT_TYPE} = constants;

const {monthMm, dateOfLastDayOfPreviousMonth} = dates;
const lastMonthDate = dateOfLastDayOfPreviousMonth();

interface Params {
  years: [string];
  month: string;
  contractType: string;
  productType: string;
}

export const ReportMonthlyUsageFc: FC<PropsWithClassName> = (props) => {
  const formState = useFormState({
    isEditing: true,
    useUrlParams: true,
    preferenceFieldNames: ['years'],
    initialEntity: {
      years: [lastMonthDate.getFullYear().toString()],
      month: monthMm(lastMonthDate),
      contractType: 'ALL',
      productType: 'ALL',
    },
  });
  const {entity, entityAs, onChange} = formState;
  const {years, month, contractType, productType} = entityAs<Params>();

  log.debug('entity', {entity});

  const {productTypes, isDirectChargeRange} = useProductTypes();
  const {currentUserBrand} = useUser();

  const filteredProductTypes = useMemo(
    () => productTypes.filter(({brandId, id}) => currentUserBrand?.id === brandId && !isDirectChargeRange(id)),
    [currentUserBrand, productTypes]
  );

  const onSettlementTotalsGraphClick = (month: ID) => {
    log.debug('onSettlementTotalsGraphClick', {month});
    onChange('month')(month);
  };

  const onYearsChange = (value: string[]) => {
    if (value.isEmpty()) {
      formState.putError('years', 'Atleast one year has to be selected.');
    }
  };

  return (
    <>
      <PageSubtitle subtitle={'Monthly Totals'} />
      <PageViewCounter page="monthly-usage" />

      <div className={classnames('SettlementMonthTotal', props.className)}>
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId={'years'}
              label={'Years'}
              options={dates.yearsFrom(2019).map((y) => y.toString())}
              type={FieldTypes.multi}
              onChangeAlso={onYearsChange}
            />
            <FieldWithFormState
              formState={formState}
              entityFieldId={'contractType'}
              label={'Contract Types'}
              options={[{id: ALL, name: 'All contracts'}, ...contractTypes]}
              type={FieldTypes.select}
              tip={
                <ul>
                  <li>
                    <span className="bold">{EXTERNAL_CONTRACT_TYPE}</span> contract which will be invoiced
                  </li>
                  <li>
                    <span className="bold">{INTERNAL_CONTRACT_TYPE}</span> contract do NOT get invoiced
                  </li>
                </ul>
              }
            />
          </FormColumn>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId={'productType'}
              label={'Service Types'}
              options={[{id: ALL, name: 'All products'}, ...filteredProductTypes]}
              type={FieldTypes.select}
            />
            <div />
          </FormColumn>
        </FormRow>
      </div>

      <ReportMonthlyUsageGraphFc
        years={years.map((year) => parseInt(year))}
        contractType={contractType}
        productType={productType}
        onClick={onSettlementTotalsGraphClick}
        className={'marginBottom'}
      />

      <div className={classnames('SettlementMonthTotal', props.className)}>
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId={'month'}
              label={'Month'}
              options={dates.monthOptionValues}
              type={FieldTypes.select}
            />
            <div />
          </FormColumn>
        </FormRow>

        <ReportMonthSummariesTableFc
          years={years}
          month={month}
          contractType={contractType}
          productType={productType}
        />
      </div>
    </>
  );
};
