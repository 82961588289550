import {gql, useApolloClient, useQuery} from '@apollo/client';
import React, {FC, useMemo} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import pendingSettlementsQuery from '../../graphql/query/pendingSettlements.graphql';
import readyForApprovalSettlementsQuery from '../../graphql/query/readyForApprovalSettlements.graphql';
import settlementQuery from '../../graphql/query/settlement.graphql';

import * as AppRoutes from '../../appRoutes';
import {useLoading} from '../../hooks/useLoading';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Settlement} from '../../model';
import ErrorBoundary from '../ErrorBoundary';
import Page from '../common/Page';
import PageTitle from '../common/PageTitle';
import {SubmenuFc, SubmenuItemFc} from '../common/Submenu';
import {SettlementArchiveFc} from './SettlementArchive';
import {SettlementOverviewFc} from './SettlementOverview';
import {SettlementPendingListFc} from './SettlementPendingList';
import {SettlementReadyForApprovalListFc} from './SettlementReadyForApprovalList';
import {SettlementSearchFc} from './SettlementSearch';

const log = getLog('SettlementMain', 'INFO');

interface PendingSettlementsQuery {
  pendingSettlements: Settlement[];
}

interface ReadyForApprovalSettlementsQuery {
  readyForApprovalSettlements: Settlement[];
}

const pendingSettlementsQueryGql = gql(pendingSettlementsQuery);
const readyForApprovalSettlementsQueryGql = gql(readyForApprovalSettlementsQuery);

export const SettlementMainFc: FC = (props) => {
  log.debug('render', props);

  const apolloClient = useApolloClient();
  const {currentUserBrand} = useUser();
  const subPath = AppRoutes.useSubPath(AppRoutes.SETTLEMENT);
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const {loading: loadingPending, data: {pendingSettlements: unfilteredPendingSettlements} = {}} =
    useQuery<PendingSettlementsQuery>(pendingSettlementsQueryGql, {
      skip: !currentUserBrand,
    });
  const pendingSettlements = useMemo(
    () => unfilteredPendingSettlements?.filter(({isComplete}) => isComplete),
    [unfilteredPendingSettlements]
  );

  log.debug('pendingSettlements', {pendingSettlements});

  const {
    loading: loadingReadyForApproval,
    data: {readyForApprovalSettlements: unfilteredReadyForApprovalSettlements} = {},
  } = useQuery<ReadyForApprovalSettlementsQuery>(readyForApprovalSettlementsQueryGql, {
    skip: !currentUserBrand,
  });
  const readyForApprovalSettlements = useMemo(
    () => unfilteredReadyForApprovalSettlements?.filter(({isComplete}) => isComplete),
    [unfilteredReadyForApprovalSettlements]
  );

  const onUpdateSettlement = (settlement: Settlement) => {
    log.info('onUpdateSettlement', settlement);

    //  Update settlement
    apolloClient.writeQuery({
      query: gql(settlementQuery),
      data: {settlement},
      variables: {id: settlement.id, customerId: settlement.customer.id},
    });

    //  Update pending settlements list
    const pendingSettlementsData = apolloClient.readQuery<PendingSettlementsQuery>({
      query: pendingSettlementsQueryGql,
    });
    let pendingSettlements =
      pendingSettlementsData?.pendingSettlements.filter(({state, isComplete}) => state === 'PENDING' && isComplete) ||
      [];
    if (settlement.state === 'PENDING') {
      pendingSettlements = [settlement, ...pendingSettlements];
    }
    apolloClient.writeQuery<PendingSettlementsQuery>({
      query: pendingSettlementsQueryGql,
      data: {pendingSettlements},
    });

    //  Update readyForApproval settlements list
    const readyForApprovalSettlementsData = apolloClient.readQuery<ReadyForApprovalSettlementsQuery>({
      query: readyForApprovalSettlementsQueryGql,
      variables: {brandId: currentUserBrand?.id},
    });
    let readyForApprovalSettlements =
      readyForApprovalSettlementsData?.readyForApprovalSettlements.filter(
        ({state, isComplete}) => state === 'READY_FOR_APPROVAL' && isComplete
      ) || [];
    if (settlement.state === 'READY_FOR_APPROVAL') {
      readyForApprovalSettlements.push(settlement);
    }
    apolloClient.writeQuery({
      query: readyForApprovalSettlementsQueryGql,
      variables: {brandId: currentUserBrand?.id},
      data: {readyForApprovalSettlements},
    });
  };

  return (
    <Page>
      {/*<BreadCrumbs>*/}
      {/*<BreadCrumb text='Settlements' link={formatWithBrand(AppRoutes.SETTLEMENT)}/>*/}
      {/*</BreadCrumbs>*/}

      <PageTitle title="Settlement" />

      <div>
        <SubmenuFc ariaLabel={'Settlement submenu'}>
          <SubmenuItemFc link={formatWithBrand(AppRoutes.SETTLEMENT_OVERVIEW)} text="Overview" />
          {/*<SubmenuItemFc link={formatWithBrand(AppRoutes.SETTLEMENT_MONTHLY)} text="Monthly" />*/}
          <SubmenuItemFc
            link={formatWithBrand(AppRoutes.SETTLEMENT_PENDING_LIST)}
            text={`Pending`}
            badge={
              pendingSettlements && pendingSettlements.length && pendingSettlements.length > 0
                ? pendingSettlements.length
                : null
            }
          />
          <SubmenuItemFc
            link={formatWithBrand(AppRoutes.SETTLEMENT_READY_FOR_APPROVAL_LIST)}
            text={'Ready For Approval'}
            badge={
              readyForApprovalSettlements &&
              readyForApprovalSettlements.length &&
              readyForApprovalSettlements.length > 0
                ? readyForApprovalSettlements.length
                : null
            }
          />
          <SubmenuItemFc link={formatWithBrand(AppRoutes.SETTLEMENT_ARCHIVE)} text="Archive" />
          <SubmenuItemFc link={formatWithBrand(AppRoutes.SETTLEMENT_SEARCH)} text="Search" />
        </SubmenuFc>

        <ErrorBoundary>
          <Routes>
            <Route index element={<Navigate to={formatWithBrand(AppRoutes.SETTLEMENT_OVERVIEW)} />} />
            <Route
              path={subPath(AppRoutes.SETTLEMENT_OVERVIEW)}
              element={
                <SettlementOverviewFc
                  pendingSettlementsCount={pendingSettlements?.length}
                  readyForApprovalSettlementsCount={readyForApprovalSettlements?.length}
                />
              }
            />
            <Route
              path={subPath(AppRoutes.SETTLEMENT_PENDING_LIST)}
              element={
                <SettlementPendingListFc
                  pendingSettlements={pendingSettlements}
                  onUpdateSettlement={onUpdateSettlement}
                  loading={loadingPending}
                />
              }
            />
            <Route
              path={subPath(AppRoutes.SETTLEMENT_READY_FOR_APPROVAL_LIST)}
              element={
                <SettlementReadyForApprovalListFc
                  readyForApprovalSettlements={readyForApprovalSettlements}
                  onUpdateSettlement={onUpdateSettlement}
                  loading={loadingReadyForApproval}
                />
              }
            />
            <Route path={subPath(AppRoutes.SETTLEMENT_ARCHIVE)} element={<SettlementArchiveFc />} />
            <Route path={subPath(AppRoutes.SETTLEMENT_SEARCH_RESULT)} element={<SettlementSearchFc />} />
            <Route path={subPath(AppRoutes.SETTLEMENT_SEARCH)} element={<SettlementSearchFc />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </Page>
  );
};
