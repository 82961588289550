import {period} from '@telia/cpa-web-common';
import classnames from 'classnames';
import React, {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react';

import {useAccessNumberHistory} from '../../hooks/useAccessNumberHistory';
import {useShowInactive} from '../../hooks/useShowInactive';
import {getLog} from '../../log';
import {ID} from '../../model';
import Loading from '../Loading';
import Error from '../common/Error';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import {Field, FieldTypes} from '../common/field';

const log = getLog('PickProductByAccessNumber', 'INFO');

interface PickProductByAccessNumberProps {
  selectedProductIds?: ID[];
  onPickProductByAccessNumber: (comaSeparatedProductIds: ID) => void;
}

interface AccessNumberProductOverview {
  accessNumber: ID;
  companyName: string;
  productId: ID;
  state: ID;
}

export const PickProductByAccessNumber: FunctionComponent<PickProductByAccessNumberProps> = (props) => {
  const {selectedProductIds = [], onPickProductByAccessNumber} = props;
  const {loading: loadingAccessNumberHistory, accessNumberHistoryList} = useAccessNumberHistory();
  const {showInactive, showInactiveComponent} = useShowInactive();

  const productOverviews = useMemo(
    () =>
      accessNumberHistoryList
        ?.filter(({accessNumber}) => accessNumber)
        .map<AccessNumberProductOverview>(
          ({accessNumber, productHistory}) =>
            productHistory
              .filter((product) => showInactive || period.activeStatus(product) === period.ACTIVE)
              ?.flatMap((product) => ({
                accessNumber,
                companyName: product.customerName,
                productId: product.id,
                state: period.activeStatus(product),
              })) as unknown as AccessNumberProductOverview
        )
        .flat() as AccessNumberProductOverview[],
    [accessNumberHistoryList, showInactive]
  );
  const [filter, setFilter] = useState('');
  const filteredProductOverviews = useMemo(
    () =>
      productOverviews?.filter(
        ({accessNumber, companyName, productId}) =>
          accessNumber.startsWith(filter) || companyName.toLowerCase().includes(filter) || productId.startsWith(filter)
      ),
    [filter, productOverviews]
  );

  const filterInput = useRef<HTMLInputElement>();
  useEffect(() => {
    log.debug('useEffect', filterInput, filterInput?.current);
    filterInput?.current?.focus();
  }, [filterInput.current]);

  log.debug('render', {
    selectedProductIds,
    loadingAccessNumberHistory,
    accessNumberHistoryList,
    productOverviews,
    filterInputCurrent: filterInput.current,
  });
  return (
    <div>
      <Field
        placeholder={'Filter'}
        icon={'search'}
        isEditing={true}
        value={filter}
        onChange={(value) => setFilter(((value as string) || '').toLowerCase())}
        inputRef={filterInput}
      />
      <div className="marginTop marginBottomHalfRem">{showInactiveComponent}</div>
      {loadingAccessNumberHistory ? (
        <Loading />
      ) : !productOverviews || productOverviews.isEmpty() ? (
        <Error>No access numbers history found</Error>
      ) : !filteredProductOverviews || filteredProductOverviews.isEmpty() ? (
        <InformationLineFc>No access numbers products found with current '{filter}' filter</InformationLineFc>
      ) : (
        <div className={''}>
          <List className={''}>
            {filteredProductOverviews.map(({accessNumber, productId, state, companyName}, i) => (
              <ListItem
                className={classnames({
                  'list__item--selected': selectedProductIds.some(
                    (selectedProductId) => selectedProductId === productId
                  ),
                })}
                key={i}
                onClick={() =>
                  productId && onPickProductByAccessNumber(selectedProductIds.cloneToggle(productId).join(', '))
                }
              >
                <div>
                  <Field
                    type={FieldTypes.checkbox}
                    value={selectedProductIds.some((selectedProductId) => selectedProductId === productId)}
                    className="width1rem"
                    isEditing={false}
                  />
                  <span className="width5rem rightText">{accessNumber}</span> -{' '}
                  <span className={classnames('width4rem', state.toLowerCase() + 'Period')}>{state}</span> -{' '}
                  <span className="width10rem">{companyName}</span>{' '}
                  <span className="width8rem"> (prod: {productId})</span>
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
};
