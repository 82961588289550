import React, {FC, PropsWithChildren} from 'react';

import './PageTitle.scss';

import {useLoading} from '../../hooks/useLoading';
import Loading from '../Loading';

interface PageTitleProps {
  title?: string;
  showLoading?: boolean;
}

const PageTitleFc: FC<PropsWithChildren<PageTitleProps>> = (props) => {
  const {isLoading, reset} = useLoading();
  reset();

  return (
    <h1 className="heading heading--level-1 page-title__title">
      {props.title || props.children}
      {props.showLoading && isLoading && (
        <div className="page-title__spinner">
          <Loading />
        </div>
      )}
    </h1>
  );
};

export default PageTitleFc;
