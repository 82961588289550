import {gql, useQuery} from '@apollo/client';
import {dates} from '@telia/cpa-web-common';
import classnames from 'classnames';
import React, {FunctionComponent, useMemo, useState} from 'react';

import settlementsQuery from '../../graphql/query/settlements.graphql';

import {useLoading} from '../../hooks/useLoading';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Settlement} from '../../model';
import AccordionItem from '../common/AccordionItem';
import {AccordionListFc} from '../common/AccordionList';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SettlementFc} from './common/Settlement';
import {SettlementTotalSumFc} from './common/SettlementTotalSum';
import {SettlementPendingLineFc} from './common/accordionHeader/SettlementPendingLine';

const log = getLog('SettlementArchive', 'INFO');

interface SettlementsQuery {
  settlements: Settlement[];
}

const {monthOptionValues, dateOfLastDayOfPreviousMonth, monthMm} = dates;
const lastMonthDate = dateOfLastDayOfPreviousMonth();
const initialYear = '' + lastMonthDate.getFullYear();
const initialMonth = monthMm(lastMonthDate);

export const SettlementArchiveFc: FunctionComponent = () => {
  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState(initialMonth);

  const {currentUserBrand} = useUser();
  const {loading, data: {settlements: unfilteredSettlements} = {}} = useQuery<SettlementsQuery>(gql(settlementsQuery), {
    skip: !currentUserBrand,
    variables: {year: parseInt(year), month: parseInt(month)},
  });
  useLoading([{query: 'settlements', isLoading: loading}]);

  const settlements = useMemo(
    () => unfilteredSettlements?.filter((settlement) => settlement.state === 'APPROVED'),
    [unfilteredSettlements]
  );

  log.debug('props', {
    year,
    month,
    loading,
    settlements,
    monthOptionValues,
    lastMonthDate,
    initialYear,
    initialMonth,
  });
  return (
    <React.Fragment>
      <PageSubtitle>
        Approved settlements archive <SettlementTotalSumFc settlements={settlements} />
      </PageSubtitle>
      <PageViewCounter page="archive" />

      <div className={classnames('SettlementArchive')}>
        <FormRow>
          <FormColumn>
            <Field
              value={'' + year}
              isEditing={true}
              options={dates.years.map((y) => '' + y)}
              type={FieldTypes.select}
              onChange={(year: string) => setYear(year)}
            />
            <Field
              value={'' + month}
              isEditing={true}
              options={monthOptionValues}
              type={FieldTypes.select}
              onChange={(month: string) => setMonth(month)}
            />
          </FormColumn>
        </FormRow>

        {
          //  TODO: refresh same month search  ---   download CSV file
          !settlements || settlements.isEmpty() ? (
            <InformationLineFc description={`No approved settlements for this month` /* on ${month} ${year}*/} />
          ) : (
            <AccordionListFc multipleOpen={true} initialAllOpen={false}>
              {settlements.map((settlement) => (
                <AccordionItem
                  id={settlement.id}
                  key={settlement.id}
                  header={<SettlementPendingLineFc settlement={settlement} />}
                  content={<SettlementFc settlementId={settlement.id} customerId={settlement.customer.id} />}
                />
              ))}
            </AccordionListFc>
          )
        }
      </div>
    </React.Fragment>
  );
};
