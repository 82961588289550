import {gql} from '@apollo/client';
import {Button, ModalDialog} from '@telia/styleguide';
import React, {FC, useState} from 'react';

import approveSettlementMutation from '../../graphql/mutation/approveSettlement.graphql';
import rejectSettlementMutation from '../../graphql/mutation/rejectSettlement.graphql';

import './SettlementPendingList.scss';

import {useLoading} from '../../hooks/useLoading';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useSelectedIds} from '../../hooks/useSelectedIds';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, Settlement} from '../../model';
import {SETTLEMENTS_APPROVE} from '../../permissions';
import FormColumn from '../common/FormColumn';
import FormRow from '../common/FormRow';
import {InformationLineFc} from '../common/InformationLine';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {SettlementTableFc} from './SettlementTable';
import {PendingSettlementFc} from './common/PendingSettlement';
import {SettlementTotalSumFc} from './common/SettlementTotalSum';

const log = getLog('SettlementReadyForApprovalList', 'INFO');

export interface SettlementReadyForApprovalListProps {
  onUpdateSettlement: (settlement: Settlement) => void;
  readyForApprovalSettlements?: Settlement[];
  loading: boolean;
}

export const SettlementReadyForApprovalListFc: FC<SettlementReadyForApprovalListProps> = ({
  loading,
  onUpdateSettlement,
  readyForApprovalSettlements,
}) => {
  useLoading([{query: 'readyForApprovalSettlements', isLoading: loading}]);
  const {selectedIds, onSelectId, onSelectAll, isSelected, areAllSelected} = useSelectedIds<Settlement>([]);
  const [showApproveSelectedDialog, setShowApproveSelectedDialog] = useState<boolean>(false);
  const {hasAnyPermission} = useUser();
  const canApprove = hasAnyPermission(SETTLEMENTS_APPROVE);
  const {showModal} = useModal();

  const onShowSettlement = (settlement: Settlement) => {
    log.debug('onShowSettlement', settlement.id);
    showModal({
      title: `${settlement.id} ${settlement.customer.company.name}`,
      content: (
        <PendingSettlementFc
          settlement={settlement}
          onApprove={onApprove(settlement.id)}
          onReject={onReject(settlement.id)}
        />
      ),
    });
  };

  const onApproveSelected = () => {
    setShowApproveSelectedDialog(true);
  };

  const onCancelApproveSelected = () => {
    setShowApproveSelectedDialog(false);
  };

  const onConfirmApproveSelected = () => {
    log.info(`onConfirmApproveSelected ${selectedIds.length} Settlements`);
    setShowApproveSelectedDialog(false);
    selectedIds.forEach((settlementId) => approveSettlement(settlementId));
  };

  const onApprove = (settlementId: ID) => () => {
    log.debug(`onApprove Settlement`, settlementId);
    // setState({hiddenSettlements: state.hiddenSettlements.cloneToggle(settlementId)});
    approveSettlement(settlementId);
  };

  const approveSettlementHook = useMutationWrap<{approveSettlement: Settlement}, {id: ID}>(
    gql(approveSettlementMutation)
  );
  const approveSettlement = (id: ID) => {
    log.debug(`Approving Settlement `, id);
    approveSettlementHook({
      loadingText: 'Approving settlement...',
      successText: 'Settlement approved',
      variables: {id},
      update: (proxy, {data}) => {
        const {approveSettlement} = data || {};
        log.info('approveSettlementMutation update', approveSettlement);
        approveSettlement && onUpdateSettlement(approveSettlement);
      },
    }).then(({data}) => {
      const {approveSettlement} = data || {approveSettlement: undefined};
      log.debug('approveSettlementMutate resolved', approveSettlement);
      approveSettlement && onSelectId(approveSettlement.id)(false);
      return approveSettlement;
    });
  };

  const rejectSettlementHook = useMutationWrap<{rejectSettlement: Settlement}, {id: ID; comment: string}>(
    gql(rejectSettlementMutation)
  );
  const rejectSettlement = (id: ID, comment: ID) => {
    log.debug(`Rejecting Settlement `, id);
    rejectSettlementHook({
      loadingText: 'Rejecting settlement...',
      successText: 'Settlement rejected',
      variables: {id, comment},
      update: (proxy, {data}) => {
        const {rejectSettlement} = data || {};
        log.info('rejectSettlementMutation update', rejectSettlement);
        rejectSettlement && onUpdateSettlement(rejectSettlement);
      },
    }).then(({data}) => {
      const {rejectSettlement} = data || {rejectSettlement: undefined};
      log.debug('rejectSettlementMutate resolved', rejectSettlement);
      rejectSettlement && onSelectId(rejectSettlement.id)(false);
      return rejectSettlement;
    });
  };

  const onReject = (settlementId: ID) => (comment: string) => {
    // setState({hiddenSettlements: state.hiddenSettlements.cloneToggle(settlementId)});
    rejectSettlement(settlementId, comment);
  };

  return (
    <Page>
      {showApproveSelectedDialog && (
        <ModalDialog
          name={'approve-settlements'}
          heading={'Approve & invoice selected settlements'}
          submitText="Approve"
          submitKind={Button.kinds.primary}
          onSubmit={onConfirmApproveSelected}
          closeText="Cancel"
          onClose={onCancelApproveSelected}
        >
          <p>Are you sure you want to APPROVE {selectedIds && selectedIds.length} settlements?</p>
        </ModalDialog>
      )}

      <PageSubtitle>
        Ready For Approval Settlements <SettlementTotalSumFc settlements={readyForApprovalSettlements} />
      </PageSubtitle>
      <PageViewCounter page="ready-for-approval" />

      {!readyForApprovalSettlements || readyForApprovalSettlements.isEmpty() ? (
        <InformationLineFc description={'No settlements ready for approval'} />
      ) : (
        <>
          {canApprove && (
            <div className={'pending-settlements__approve-all-form'}>
              <FormRow>
                <FormColumn>
                  <Field
                    type={FieldTypes.checkbox}
                    isEditing={true}
                    label={'Select all'}
                    value={areAllSelected(readyForApprovalSettlements)}
                    onChange={onSelectAll(readyForApprovalSettlements)}
                  />
                </FormColumn>
                <FormColumn>
                  <Button
                    kind={Button.kinds.primary}
                    text={`Approve & invoice selected (${selectedIds.length})`}
                    onClick={onApproveSelected}
                    isDisabled={selectedIds.isEmpty()}
                  />
                </FormColumn>
              </FormRow>
            </div>
          )}

          <SettlementTableFc
            settlements={readyForApprovalSettlements}
            onShowSettlement={onShowSettlement}
            isSelected={isSelected}
            onSelectId={canApprove ? onSelectId : undefined}
          />
        </>
      )}
    </Page>
  );
};
